import React from 'react';
import CallToAction from '../../../molecules/CallToAction/CallToAction';

const TitleBlock = ({
  isFirstStep,
  isLastStep,
  step,
  stepLabel,
  stepTitle,
  subTitle,
  fieldName,
  handleGoBack,
  handleSubmit,
  handleStep,
  children,
}) => (
  <div className="title-block">
    <p className="step-label">{stepLabel}</p>
    <h4 className="step-title">{stepTitle}</h4>
    <p className="sub-title">{subTitle}</p>
    {children}
    <div className="action-buttons">
      {!isFirstStep && (
        <CallToAction
          variant="tertiary"
          size="md"
          className="eval-btn"
          value="Go Back"
          customHandleClick={handleGoBack}
        />
      )}
      <CallToAction
        variant="secondary"
        size="md"
        className="eval-btn"
        value="Continue"
        customHandleClick={() =>
          isLastStep ? handleSubmit() : handleStep(step, fieldName)
        }
      />
      {isFirstStep && (
        <p className="notice">
          This Shop Business Valuation Calculator is provided for informational
          purposes only, and is not intended to and does not replace
          professional financial guidance. Please consult a financial
          professional.
        </p>
      )}
    </div>
  </div>
);

export default TitleBlock;
